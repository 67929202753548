<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="CodesandboxIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ dataItem.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Ürün Kartı
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Üretim Malzeme Listesi</b-card-title>
        <b-button
          variant="primary"
          :to="'/app/products/additem/' + $route.params.id"
        >
          <feather-icon icon="PlusIcon" />
          Ekle
        </b-button>
      </b-card-header>
      <b-table
        v-if="dataList.length > 0 "
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(created)="data">
          {{ moment(data.item.created).format('DD.MM.YYYY') }}
          <div>
            <small class="text-warning">{{ data.item.username }}</small>
          </div>
        </template>
        <template #cell(stocks)="data">
          {{ data.item.stocks }}
          <div
            v-if="data.item.notes"
            class="text-muted font-small-2"
          >
            {{ data.item.notes }}
          </div>
        </template>
        <template #cell(min_amount)="data">
          {{ data.item.min_amount | toNumber }}
          <small
            v-if="data.item.units && data.item.min_amount"
            class="text-primary"
          >
            {{ data.item.units }}
          </small>

        </template>
        <template #cell(max_amount)="data">
          {{ data.item.max_amount | toNumber }}
          <small
            v-if="data.item.units && data.item.max_amount"
            class="text-primary"
          >{{ data.item.units }}</small>
        </template>
        <template #cell(control)="data">
          <div class="text-right">
            <list-buttons
              :primary-text="'Düzenle'"
              :primary-action="'/app/products/edititem/' + data.item.id"
              :delete-action="removeItem"
              :data-id="data.item.id"
            />
          </div>
        </template>
      </b-table>
      <b-alert
        v-else
        show
        variant="info"
        class="mr-2 ml-2"
      >
        <div class="alert-body text-center">
          <div class="lead">
            Üretim malzeme kaydı bulunamadı.
          </div>
          <div class="mt-1">
            <b-button
              variant="info"
              size="sm"
              :to="'/app/products/additem/' + $route.params.id"
            >
              Üretim Malzemesi Ekle
            </b-button>
          </div>
        </div>
      </b-alert>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BAlert,
  BButton,
  BCardFooter,
  BPagination,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCardText,
} from 'bootstrap-vue'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'

export default {
  name: 'ViewProduct',
  components: {
    ListButtons,
    BCardFooter,
    BButton,
    BTable,
    BPagination,
    BAlert,
    BCardHeader,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCardText,

  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
      fields: [
        {
          key: 'created',
          label: 'Tarih',
          thClass: 'text-nowrap width-50',
          tdClass: 'text-nowrap width-50',
        },
        {
          key: 'stocks',
          label: 'Malzeme',
          thClass: 'text-nowrap',
        },
        {
          key: 'min_amount',
          label: 'En Düşük Miktar',
          thClass: 'text-nowrap width-50',
          tdClass: 'text-nowrap width-50',
        },
        {
          key: 'max_amount',
          label: 'En Yüksek Miktar',
          thClass: 'text-nowrap width-50',
          tdClass: 'text-nowrap width-50',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap width-50',
          tdClass: 'text-nowrap width-50',
        },
      ],
      dataQuery: {
        where: {
          'product_items.id_products': this.$route.params.id,
        },
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    moment() {
      return moment
    },
    dataItem() {
      return this.$store.getters['products/dataItem']
    },
    dataList() {
      return this.$store.getters['productItems/dataList']
    },
    dataCounts() {
      return this.$store.getters['productItems/dataCounts']
    },
    saveLinesStatus() {
      return this.$store.getters['productItems/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getData()
    },
    saveLinesStatus(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getLines()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getData()
    this.getLines()
  },
  methods: {
    getData() {
      this.$store.dispatch('products/getDataItem', this.$route.params.id)
    },
    getLines() {
      this.$store.dispatch('productItems/getDataList', this.dataQuery)
    },
    removeItem(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('productItems/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
